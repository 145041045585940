<template>
  <div class="detail">
    <div class="detail-icon">
      <img :src="icon" alt="">
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  name: 'Error',
  data () {
    return {
      icon: require('../../assets/images/404.png')
    }
  },
  created () {
  },
  mounted () {},
  methods: {}
}
</script>

<style lang="less" scoped>
.detail{
  padding: 100px 0;
}
.detail-icon{
  width: 500px;
  height: 351px;
  margin: auto;
}
.detail-icon img{
  display: block;
  height: 100%;
  width: 100%;
}
</style>
